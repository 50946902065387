@import "../../../theme/index.module.scss";


$search-font-map: (
  null: (ms(2), vr(1))
);

.SearchForm {
  position: relative;
  width: 100%;
}

.Search {
  width: 100%;
  position: fixed;
  top: 4.8em;
  height: 3.75em;
  background: #E60201;
  visibility: hidden;
  right: -100%;
  transition: right .3s, top .3s, padding .3s;
  opacity: 0;
  @include bp($larger) {
    max-width: 30%;
    background: $red linear-gradient(to bottom, rgb(215, 10, 22) 0%, rgb(227, 11, 23) 71%, rgb(222, 10, 23) 100%);
    padding: 0 10px;
    right: 0;
    z-index: 0;
  }
}

.Search_input {
  position: absolute;
  top: .6em;
  left: 1em;
  opacity: 1;
  border: none;
  outline: none;
  background: #fff;
  width: 80%;
  height: 30px;
  margin: 0;
  z-index: 10;
  padding: 5px 40px 5px 45px;
  font-family: inherit;
  font-size: 20px;
  border-radius: 5px;
}

.Search_close {
  background: transparent;
  border: none;
  outline: none;
  z-index: 90;
  width: 50px;
  height: 30px;
  display: block;
  position: absolute;
  right: 0;
  top: .8em;
  margin: 0;
  text-align: center;
  cursor: pointer;

  div {

    &:before {
      border: none !important;
    }

    svg {
      path {
        fill: white;
      }
    }
  }
}

.Search_submit {
  background: transparent;
  border: none;
  outline: none;
  z-index: 90;
  width: 40px;
  height: 40px;
  display: block;
  position: absolute;
  left: 1.5em;
  top: .8em;
  margin: 0;
  text-align: center;
  cursor: pointer;

  div {
    width: 18px !important;
    height: 26px !important;

    svg {
      height: 16px !important;
    }
  }
}

.SearchOpen {
  composes: Search;
  visibility: visible;
  opacity: 1;
  z-index: 4;
  right: 0;
  @include bp($larger) {
    padding: 0 10px;
    right: 5%;
  }
}
